import * as React from "react";
import type { HeadFC } from "gatsby";
import { CustomHead } from "../components/shell/CustomHead";
import Shell from "../components/shell/Shell";
import ImageHero from "../components/shared/ImageHero";
import PrivacyArticle from "../components/articles/privacy";

const pageDescription =
  "Your data is encrypted and stored safely in the cloud. That way, you're free to manage your finances the way you want.";
const pagesBreadcrumb = [
  {
    name: "Privacy",
    to: "/privacy",
  },
];

const PrivacyPage = () => {
  return (
    <Shell>
      <ImageHero
        title="Your privacy matters"
        description={pageDescription}
        pages={pagesBreadcrumb}
      />
      <PrivacyArticle />
    </Shell>
  );
};

export default PrivacyPage;

export const Head: HeadFC = () => (
  <CustomHead title="Privacy" description={pageDescription} />
);
